// EventsList.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Camera } from 'lucide-react';
import './EventsList.css';

const EventsList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [addingAttendee, setAddingAttendee] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/events`);
        if (!response.ok) throw new Error('Failed to fetch events');
        const data = await response.json();
        setEvents(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleQRCodesClick = (eventId) => {
    navigate(`/event/qrcodes/${eventId}`);
  };

  const handlePhotosClick = (eventId) => {
    navigate(`/event/${eventId}/all-photos`);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading events...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <div className="error-message">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="events-container">
      <button className="back-button" onClick={() => navigate(-1)}>
        <ArrowLeft size={20} />
         Back
      </button>
      <h1 className="events-title">All Events</h1>
      <div className="events-grid">
        {events.map((event) => (
          <div key={event.id} className="event-card">
            <h2 className="event-name">{event.name}</h2>
            <div className="event-details">
              <div className="event-detail">
                <svg className="icon" viewBox="0 0 24 24">
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"/>
                  <line x1="16" y1="2" x2="16" y2="6"/>
                  <line x1="8" y1="2" x2="8" y2="6"/>
                  <line x1="3" y1="10" x2="21" y2="10"/>
                </svg>
                <span>{new Date(event.date).toLocaleDateString()}</span>
              </div>
              <div className="event-detail">
                <svg className="icon" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="10"/>
                  <polyline points="12 6 12 12 16 14"/>
                </svg>
                <span>{event.time}</span>
              </div>
              <div className="event-detail">
                <svg className="icon" viewBox="0 0 24 24">
                  <rect x="3" y="3" width="7" height="7"/>
                  <rect x="14" y="3" width="7" height="7"/>
                  <rect x="14" y="14" width="7" height="7"/>
                  <rect x="3" y="14" width="7" height="7"/>
                </svg>
                <span>{event.attendees_count} QR codes created</span>
              </div>
              <div className="event-detail">
                <svg className="icon" viewBox="0 0 24 24">
                  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM12 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
                </svg>
                <span>{event.venue}</span>
              </div>
              <div className="event-detail">
                <svg className="icon" viewBox="0 0 24 24">
                  <path d="M4 7V4h16v3"/>
                  <path d="M9 20h6"/>
                  <path d="M12 4v16"/>
                </svg>
                <span>{event.type}</span>
              </div>
            </div>
            <div className="event-footer">
              <div className="event-actions">
                <button 
                  className="action-button qr-button"
                  onClick={() => handleQRCodesClick(event.id)}
                >
                  <svg className="icon" viewBox="0 0 24 24">
                    <rect x="3" y="3" width="7" height="7"/>
                    <rect x="14" y="3" width="7" height="7"/>
                    <rect x="14" y="14" width="7" height="7"/>
                    <rect x="3" y="14" width="7" height="7"/>
                  </svg>
                  QR Codes
                </button>
                <button 
                  className="action-button photos-button"
                  onClick={() => handlePhotosClick(event.id)}
                >
                  <svg className="icon" viewBox="0 0 24 24">
                    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"/>
                    <circle cx="12" cy="13" r="4"/>
                  </svg>
                  Photos
                </button>
                <button 
                  className="action-button logs-button"
                  onClick={async (e) => {
                    e.preventDefault();
                    try {
                      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/events/${event.id}/page-views`);
                      
                      if (!response.ok) {
                        throw new Error('Failed to download logs');
                      }

                      const blob = await response.blob();
                      const url = window.URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = url;
                      link.download = `qr_hit_logs_${event.name.replace(' ', '_')}.csv`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (err) {
                      setError('Failed to download logs: ' + err.message);
                    }
                  }}
                >
                  <svg className="icon" viewBox="0 0 24 24">
                    <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4"/>
                    <polyline points="7 10 12 15 17 10"/>
                    <line x1="12" y1="15" x2="12" y2="3"/>
                  </svg>
                  Scan Logs
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {events.length === 0 && (
        <div className="no-events">
          No events found
        </div>
      )}
    </div>
  );
};

export default EventsList;