import React from 'react';
import { useNavigate } from 'react-router-dom';
import { QrcodeOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import './HomePage.css';

function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="home-page-container">
      <header className="home-header">
        <div className='centerlogo'>
      <svg width="107" height="49" viewBox="0 0 107 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.8 26.9999C47.8 38.4875 38.4875 47.7999 27 47.7999C15.5125 47.7999 6.2 38.4875 6.2 26.9999C6.2 15.5124 15.5125 6.19995 27 6.19995C38.4875 6.19995 47.8 15.5124 47.8 26.9999Z" stroke="black" stroke-width="2"/>
<path d="M42.8 21.9999C42.8 33.4875 33.4875 42.7999 22 42.7999C10.5125 42.7999 1.2 33.4875 1.2 21.9999C1.2 10.5124 10.5125 1.19995 22 1.19995C33.4875 1.19995 42.8 10.5124 42.8 21.9999Z" stroke="black" stroke-width="2"/>
<path d="M59.8295 19H57.6136L64.0227 1.54541H66.2045L72.6136 19H70.3977L65.1818 4.30677H65.0455L59.8295 19ZM60.6477 12.1818H69.5795V14.0568H60.6477V12.1818Z" fill="black"/>
<path d="M89.1733 1.54541V19H87.1278L77.6165 5.29541H77.446V19H75.3324V1.54541H77.3778L86.9233 15.284H87.0937V1.54541H89.1733Z" fill="black"/>
<path d="M91.9027 1.54541H94.3232L99.1641 9.69314H99.3686L104.21 1.54541H106.63L100.323 11.8068V19H98.2095V11.8068L91.9027 1.54541Z" fill="black"/>
<path d="M65.4545 38.5454H67.7727L69.7159 41.1022L70.2273 41.784L73.125 45.6363H70.8068L68.8977 43.0795L68.4205 42.4318L65.4545 38.5454ZM73.8409 35.2727C73.8409 37.1136 73.5085 38.7045 72.8437 40.0454C72.179 41.3863 71.267 42.4204 70.108 43.1477C68.9489 43.875 67.625 44.2386 66.1364 44.2386C64.6477 44.2386 63.3239 43.875 62.1648 43.1477C61.0057 42.4204 60.0937 41.3863 59.429 40.0454C58.7642 38.7045 58.4318 37.1136 58.4318 35.2727C58.4318 33.4318 58.7642 31.8409 59.429 30.5C60.0937 29.159 61.0057 28.125 62.1648 27.3977C63.3239 26.6704 64.6477 26.3068 66.1364 26.3068C67.625 26.3068 68.9489 26.6704 70.108 27.3977C71.267 28.125 72.179 29.159 72.8437 30.5C73.5085 31.8409 73.8409 33.4318 73.8409 35.2727ZM71.7955 35.2727C71.7955 33.7613 71.5426 32.4858 71.0369 31.446C70.5369 30.4062 69.858 29.6193 69 29.0852C68.1477 28.5511 67.1932 28.284 66.1364 28.284C65.0795 28.284 64.1222 28.5511 63.2642 29.0852C62.4119 29.6193 61.733 30.4062 61.2273 31.446C60.7273 32.4858 60.4773 33.7613 60.4773 35.2727C60.4773 36.784 60.7273 38.0596 61.2273 39.0994C61.733 40.1392 62.4119 40.9261 63.2642 41.4602C64.1222 41.9943 65.0795 42.2613 66.1364 42.2613C67.1932 42.2613 68.1477 41.9943 69 41.4602C69.858 40.9261 70.5369 40.1392 71.0369 39.0994C71.5426 38.0596 71.7955 36.784 71.7955 35.2727Z" fill="black"/>
<path d="M77.3949 44V26.5454H83.2926C84.6562 26.5454 85.7756 26.7784 86.6506 27.2443C87.5256 27.7045 88.1733 28.338 88.5937 29.1448C89.0142 29.9517 89.2244 30.8693 89.2244 31.8977C89.2244 32.9261 89.0142 33.838 88.5937 34.6335C88.1733 35.4289 87.5284 36.0539 86.6591 36.5085C85.7898 36.9573 84.679 37.1818 83.3267 37.1818H78.554V35.2727H83.2585C84.1903 35.2727 84.9403 35.1363 85.5085 34.8636C86.0824 34.5909 86.4972 34.2045 86.7528 33.7045C87.0142 33.1988 87.1449 32.5965 87.1449 31.8977C87.1449 31.1988 87.0142 30.588 86.7528 30.0653C86.4915 29.5426 86.0739 29.1392 85.5 28.8551C84.9261 28.5653 84.1676 28.4204 83.2244 28.4204H79.5085V44H77.3949ZM85.6108 36.159L89.9062 44H87.4517L83.2244 36.159H85.6108Z" fill="black"/>
<path d="M106.792 32H104.678C104.553 31.392 104.335 30.8579 104.022 30.3977C103.715 29.9375 103.34 29.5511 102.897 29.2386C102.46 28.9204 101.974 28.6818 101.44 28.5227C100.906 28.3636 100.349 28.284 99.7692 28.284C98.7124 28.284 97.755 28.5511 96.897 29.0852C96.0447 29.6193 95.3658 30.4062 94.8601 31.446C94.3601 32.4858 94.1101 33.7613 94.1101 35.2727C94.1101 36.784 94.3601 38.0596 94.8601 39.0994C95.3658 40.1392 96.0447 40.9261 96.897 41.4602C97.755 41.9943 98.7124 42.2613 99.7692 42.2613C100.349 42.2613 100.906 42.1818 101.44 42.0227C101.974 41.8636 102.46 41.6278 102.897 41.3153C103.34 40.9971 103.715 40.6079 104.022 40.1477C104.335 39.6818 104.553 39.1477 104.678 38.5454H106.792C106.633 39.4375 106.343 40.2358 105.923 40.9403C105.502 41.6448 104.979 42.2443 104.354 42.7386C103.729 43.2272 103.028 43.5994 102.249 43.8551C101.477 44.1108 100.65 44.2386 99.7692 44.2386C98.2805 44.2386 96.9567 43.875 95.7976 43.1477C94.6385 42.4204 93.7266 41.3863 93.0618 40.0454C92.397 38.7045 92.0646 37.1136 92.0646 35.2727C92.0646 33.4318 92.397 31.8409 93.0618 30.5C93.7266 29.159 94.6385 28.125 95.7976 27.3977C96.9567 26.6704 98.2805 26.3068 99.7692 26.3068C100.65 26.3068 101.477 26.4346 102.249 26.6903C103.028 26.946 103.729 27.321 104.354 27.8153C104.979 28.3039 105.502 28.9005 105.923 29.6051C106.343 30.3039 106.633 31.1022 106.792 32Z" fill="black"/>
</svg></div>

      </header>
      <div className="home-main-content">
        <div className="home-card">
          <div className="home-header-section">
            <h2>Welcome to AnyQRC</h2>
            <div className="home-divider"></div>

            <p>Scan QR codes, create events, and manage </p>
            <p>attendee's photos with ease. </p>
          </div>
          <div className="home-buttons-section">
            <div className="home-buttons-container">
              <button
                className="home-button home-button-primary"
                onClick={() => navigate('/create_event')}
              >
                <PlusOutlined className="home-button-icon" />
                Create Event
              </button>
              <button
                className="home-button home-button-secondary"
                onClick={() => navigate('/events')}
              >
                <UnorderedListOutlined className="home-button-icon" />
                My Events
              </button>
              <button
                className="home-button home-button-secondary"
                onClick={() => navigate('/scan')}
              >
                <QrcodeOutlined className="home-button-icon" />
                Scan QR Code
              </button>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-heart">
        Made with <span role="img" aria-label="heart">🤍</span>  by Big Kitty Labs 
      </p>
    </div>
  );
}

export default HomePage;