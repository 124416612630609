import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEvent, sendQRCodesEmail } from '../api';
import JSZip from 'jszip';
import './EventQRCodesPage.css';
import { 
  Layout, 
  Card, 
  Typography, 
  Button, 
  Spin, 
  message, 
  Upload 
} from 'antd';
import {
  LeftOutlined,
  UploadOutlined,
  DownloadOutlined,
  CloudDownloadOutlined,
  CloseCircleTwoTone,
  CloseOutlined
} from '@ant-design/icons';

function EventQRCodesPage() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [downloadingQRs, setDownloadingQRs] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newAttendee, setNewAttendee] = useState({ name: '' });
  const [addingAttendee, setAddingAttendee] = useState(false);
  const loadEventData = useCallback(async () => {
    try {
      setLoading(true);
      const eventData = await getEvent(eventId);
      setEvent(eventData);
    } catch (err) {
      setError(err.error || 'Failed to load event data');
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    loadEventData();
  }, [loadEventData]);

  const handleAddAttendee = async (e) => {
    e.preventDefault();
    try {
      setAddingAttendee(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/add_attendee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event_id: eventId,
          name: newAttendee.name,
          contact: '' // Sending empty contact for API compatibility
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add attendee');
      }

      // Reset form and refresh data
      setNewAttendee({ name: '' });
      setShowAddForm(false);
      await loadEventData();
      
    } catch (err) {
      setError('Failed to add attendee: ' + err.message);
    } finally {
      setAddingAttendee(false);
    }
  };

  const handleDeleteAttendee = async (attendeeId) => {
    if (!window.confirm('Are you sure you want to delete this attendee?')) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/attendee/${attendeeId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete attendee');
      }

      await loadEventData();
    } catch (err) {
      setError('Failed to delete attendee: ' + err.message);
    }
  };

  const handleDownloadAll = async () => {
    try {
      setDownloadingQRs(true);
      const zip = new JSZip();
      
      event.attendees.forEach(attendee => {
        const base64Data = attendee.qr_code_base64;
        const binaryData = atob(base64Data);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
          byteArray[i] = binaryData.charCodeAt(i);
        }
        zip.file(`${attendee.name}_qrcode.png`, byteArray);
      });
      
      const blob = await zip.generateAsync({ type: 'blob' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${event.name}_QRCodes.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      setError('Failed to download QR codes');
    } finally {
      setDownloadingQRs(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      setEmailSending(true);
      await sendQRCodesEmail(eventId);
      alert('QR codes sent successfully!');
    } catch (err) {
      setError(err.error || 'Failed to send QR codes via email');
    } finally {
      setEmailSending(false);
    }
  };

  if (loading) return <div className="loading">Loading QR codes...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!event) return <div className="error">Event not found</div>;

  return (
    <div className="page">
        <div className="header-event">
            <div className="header-left-event">
              <div className='cross-right-event'>
              <Button
                icon={<CloseOutlined style={{ fontSize: '32px',}} />}
                onClick={() => navigate(-1)}
                style={{ border: 'none', background: 'transparent' }}
              >
              </Button>
              </div>
              <h2>
                {event?.name} - QR Codes
              </h2>
            </div>
          </div>

      <section className="event-info">
        <div className="info-grid">
          <div className="info-item">
            <span className="label">Date</span>
            <span className="value">{event.date}</span>
          </div>
          <div className="info-item">
            <span className="label">Time</span>
            <span className="value">{event.time}</span>
          </div>
          <div className="info-item">
            <span className="label">Location</span>
            <span className="value">{event.venue}</span>
          </div>
          <div className="info-item">
            <span className="label">Type</span>
            <span className="value">{event.type}</span>
          </div>
          <div className="info-item">
            <span className="label">Phone</span>
            <span className="value">{event.phone}</span>
          </div>
          <div className="info-item">
            <span className="label">Attendees</span>
            <span className="value">{event.attendees.length}</span>
          </div>
        </div>
        <div className="description">
          <h3>Description</h3>
          <p>{event.description}</p>
        </div>
      </section>

      <div className="actions">
        <button 
          className="primary-button"
          onClick={handleDownloadAll}
          disabled={downloadingQRs}
        >
          {downloadingQRs ? 'Downloading...' : 'Download All QR Codes'}
        </button>
        <button 
          className="secondary-button"
          onClick={() => setShowAddForm(true)}
        >
          Add Attendee
        </button>
      </div>

      {showAddForm && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>Add New Attendee</h3>
              <button 
                className="close-button"
                onClick={() => setShowAddForm(false)}
              >
                ×
              </button>
            </div>
            <form onSubmit={handleAddAttendee}>
              <div className="form-field">
                <label>Name</label>
                <input
                  type="text"
                  value={newAttendee.name}
                  onChange={(e) => setNewAttendee({ name: e.target.value })}
                  required
                  placeholder="Enter attendee name"
                />
              </div>
              <div className="modal-footer">
                <button 
                  type="submit" 
                  className="primary-button"
                  disabled={addingAttendee}
                >
                  {addingAttendee ? 'Adding...' : 'Add Attendee'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="qr-grid">

        {event.attendees.map((attendee) => (
          <div key={attendee.id} className="qr-card">
              <Button
                icon={<CloseOutlined style={{ fontSize: '32px', color: 'red'
                }} />}
                onClick={() => handleDeleteAttendee(attendee.id)}
                style={{ border: 'none', background: 'transparent' }}
              >
              </Button>
            <div className="qr-image">
              <img 
                src={`data:image/png;base64,${attendee.qr_code_base64}`}
                alt={`QR Code for ${attendee.name}`} 
              />
            </div>
            <div className="attendee-name">
              <h3>{attendee.name}</h3>
            </div>
            <div className="card-actions">
              <button 
                onClick={() => {
                  const link = document.createElement('a');
                  const byteCharacters = atob(attendee.qr_code_base64);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], { type: 'image/png' });
                  link.href = URL.createObjectURL(blob);
                  link.download = `${attendee.name}_qrcode.png`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }} 
                className="secondary-button"
              >
                Download
              </button>
              <button 
                onClick={() => {
                    const url = `${attendee.qr_code_url}`;
                    navigator.clipboard.writeText(url);
                    alert('Link copied to clipboard!');
                }}
                className="primary-button"
              >
                Copy Link
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EventQRCodesPage;