import React, { useState, useEffect , useRef} from 'react';
import { Camera, Upload, X, Check } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import './AttendeePhotoPage.css';

const AttendeePhotoPage = () => {
  const { eventIdHash, attendeeNameHash } = useParams();
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(null);
  const uploadSectionRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const userDetailsStr = localStorage.getItem('verifiedUserDetails');
    if (!userDetailsStr) {
      navigate('/verify', {
        state: {
          params: { eventIdHash, attendeeNameHash }
        }
      });
      return;
    }
    
    try {
      const userDetails = JSON.parse(userDetailsStr);
      setVerifiedUser(userDetails);
    } catch (error) {
      console.error('Error parsing user details:', error);
      navigate('/verify', {
        state: {
          params: { eventIdHash, attendeeNameHash }
        }
      });
    }
  }, [eventIdHash, attendeeNameHash, navigate]);

  const uploadFile = async (file, index) => {
    if (!verifiedUser) {
      throw new Error('User verification required');
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploaded_by_name', verifiedUser.name);
    formData.append('uploaded_by_phone', verifiedUser.phoneNumber);

    try {
      setUploadStatus(prev => ({
        ...prev,
        [index]: { progress: 0, status: 'uploading' }
      }));

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/upload/${eventIdHash}/${attendeeNameHash}`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      setUploadStatus(prev => ({
        ...prev,
        [index]: { progress: 100, status: 'complete', url: data.file_url }
      }));

      return true;
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus(prev => ({
        ...prev,
        [index]: { progress: 0, status: 'error', error: error.message }
      }));
      return false;
    }
  };

  const handleSelectPhotos = () => {
    uploadSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => {
      fileInputRef.current?.click();
    }, 500);
  };

  if (!verifiedUser) {
    return null;
  }

  const handleUpload = async () => {
    if (!verifiedUser) {
      navigate('/verify', {
        state: {
          params: { eventIdHash, attendeeNameHash }
        }
      });
      return;
    }

    setIsUploading(true);
    setUploadComplete(false);

    const uploads = selectedFiles.map((file, index) => uploadFile(file, index));
    const results = await Promise.all(uploads);

    const allSuccessful = results.every(result => result === true);
    setIsUploading(false);
    
    if (allSuccessful) {
      setUploadComplete(true);
      setTimeout(() => {
        setSelectedFiles([]);
        setPreviews([]);
        setUploadStatus({});
        setUploadComplete(false);
      }, 2000);
    }
  };

  // Don't render anything while checking verification
  if (!verifiedUser) {
    return null;
  }

  return (
    <div className="upload-container">
      <div className="image-background"></div>

      <div className="main-content-upload">
        <div className="card">
          <div className="header-section">
            <div className="decorative-top"></div>
            <div className="corner-decoration top-left"></div>
            <div className="corner-decoration top-right"></div>
            
            <p className="subheader">WITH HEARTFELT GRATITUDE</p>
            
            <h1 className="title">
              <span className="name">ELIZABETH ANN PLANK</span>
              <span className="and">and</span>
              <span className="name">MICHAEL PATRICK KARNES</span>
            </h1>
            
            <div className="divider"></div>

            <div className="select-photos-card" onClick={handleSelectPhotos}>
              <Camera size={24} />
              <span>Share Clicked Photos</span>
            </div>

            <div className="wedding-details">
              <span className="anda">Thank you {verifiedUser.name} for celebrating </span>
              <span className="anda">our wedding day with us.</span>
              <span className="anda">Your presence made it perfect.</span>
              <span className="thank-you-message highlight">NOVEMBER 9, 2024</span>
            </div>

            <div className="corner-decoration bottom-left"></div>
            <div className="corner-decoration bottom-right"></div>
          </div>

          <div className="upload-section" ref={uploadSectionRef}>
            <div className="section-header">
              <h2>Capture the happening!</h2>
              <p>Help us cherish these beautiful moments forever ∞</p>
              <p className="upload-info">Uploading as: {verifiedUser.name}</p>
            </div>

            <div className="upload-area">
              <label className="upload-label">
                <div className="camera-icon">
                  <Camera />
                </div>
                <span>Select Photos</span>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const files = Array.from(e.target.files || []);
                    setSelectedFiles(prev => [...prev, ...files]);
                    setPreviews(prev => [...prev, ...files.map(f => URL.createObjectURL(f))]);
                  }}
                  className="file-input"
                  multiple
                />
              </label>
              <p className="format-hint">JPG, PNG, GIF formats accepted</p>
            </div>

            {previews.length > 0 && (
              <div className="preview-grid">
                {previews.map((preview, index) => (
                  <div key={index} className="preview-item">
                    <img
                      src={preview}
                      alt={`Preview ${index + 1}`}
                      className="preview-image"
                    />
                    <button
                      onClick={() => {
                        setSelectedFiles(prev => prev.filter((_, i) => i !== index));
                        setPreviews(prev => prev.filter((_, i) => i !== index));
                        setUploadStatus(prev => {
                          const newStatus = { ...prev };
                          delete newStatus[index];
                          return newStatus;
                        });
                      }}
                      className="remove-button"
                    >
                      <X size={16} />
                    </button>
                    {uploadStatus[index] && (
                      <div className="upload-status">
                        {uploadStatus[index].status === 'uploading' && (
                          <div className="progress-bar">
                            <div 
                              className="progress-fill"
                              style={{ width: `${uploadStatus[index].progress}%` }}
                            ></div>
                          </div>
                        )}
                        {uploadStatus[index].status === 'error' && (
                          <div className="error-message">Upload failed</div>
                        )}
                        {uploadStatus[index].status === 'complete' && (
                          <div className="success-icon">
                            <Check size={16} color="green" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            <button
              onClick={handleUpload}
              disabled={selectedFiles.length === 0 || isUploading}
              className={`upload-button ${
                selectedFiles.length === 0 || isUploading ? 'disabled' : ''
              } ${uploadComplete ? 'success' : ''}`}
            >
              {isUploading ? (
                'Uploading...'
              ) : uploadComplete ? (
                <>
                  <Check className="check-icon" size={20} />
                  <span>Upload Complete!</span>
                </>
              ) : (
                <>
                  <Upload size={20} />
                  <span>Upload {selectedFiles.length} Photo{selectedFiles.length !== 1 ? 's' : ''}</span>
                </>
              )}
            </button>

            <p className="thanda">
              Thank you for helping us preserve these cherished moments
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendeePhotoPage;